import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import CheckIcon from '../../shared/assets/icons/Check';
import CloseIcon from '../../shared/assets/icons/Close';
import TrashIcon from '../../shared/assets/icons/Trash';
import { TASK_CATEGORIES } from '../../shared/constants';
import PieChart from '../../shared/components/PieChart';
import { addNumbers, toFixedNumber } from '../../shared/utils/taskUtils';
import CategoriesModal from './CategoriesModal';

const normalizeUser = (user) => {
  let totalHours = 0;
  user.categories.forEach((cat) => {
    totalHours += cat.hours;
  });
  const chartData = user.categories.map((cat) => ({
    ...cat,
    percentage: toFixedNumber((100 / totalHours) * cat.hours, 2),
    value: cat.hours,
    ...TASK_CATEGORIES[cat.name],
  }));
  return { ...user, chartData, totalHours: toFixedNumber(totalHours, 1) };
};

const ViewTeamReports = () => {
  const [team, setTeam] = useState({ users: [] });
  const [re, setRE] = useState({});
  const [newMemberEmail, setNewMemberEmail] = useState('');
  const [teamChart, setTeamChart] = useState(null);
  const [modalCategory, setModalCategory] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const { teamId } = useParams();
  const { currentUser } = useSelector((state) => state.auth);

  const fetchTeam = () => {
    const url =
      currentUser.role === 'sa' ? `/team-reports/${teamId}` : '/team-reports';
    axios
      .get(url)
      .then((resp) => {
        const users = resp.data?.team?.users?.map(normalizeUser) || [];
        // setTeam({ ...resp.data?.team, users });
        setTeam((prevState) => ({ ...prevState, users }));
        const chartData = Object.values(TASK_CATEGORIES).map((cat) => ({
          ...cat,
          value: 0,
        }));
        const teamTotal = toFixedNumber(
          users.reduce((prev, curr) => prev + curr.totalHours, 0),
          1
        );
        users.forEach((user) => {
          user.chartData.forEach((cat) => {
            const chartLine = chartData.find((c) => c.id === cat.id);
            chartLine.value = addNumbers(chartLine.value, cat.value);
          });
        });
        chartData.forEach(
          (c) => (c.percentage = toFixedNumber((100 / teamTotal) * c.value, 2))
        );
        setTeamChart(chartData);
      })
      .catch(console.log);
  };

  useEffect(() => {
    fetchTeam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, currentUser.role]);

  const getPeople = useCallback(() => {
    if (team.users.length < 5) {
      return [team.users, []];
    }
    const middle = Math.ceil(team.users.length / 2);
    return [team.users.slice(0, middle), team.users.slice(middle)];
  }, [team.users]);

  const resendEmail = async (userId) => {
    await axios
      .post(`/user/send-invite/${userId}`, { teamId })
      .then((resp) => {
        if (resp.data.success) {
          const newRE = { ...re };
          newRE[userId] = true;
          setRE(newRE);
        }
      })
      .catch((err) => {});
  };

  const addMember = async () => {
    try {
      const response = await axios.post(`/user/add`, { 
        email: newMemberEmail, 
        teamId: teamId || currentUser.TeamId 
      });
      
      if (response.data.success) {
        setNewMemberEmail('');
        fetchTeam();
      } else {
        console.error('Failed to add member:', response.data);
        alert('Failed to add member. Please try again.');
      }
    } catch (err) {
      console.error('Error adding member:', err.response?.data || err.message);
      alert('Error adding member. Please try again.');
    }
  };

  const deleteMember = async (user) => {
    // eslint-disable-next-line no-restricted-globals
    const result = confirm(
      `Are you sure you want to delete ${user.name || user.email}?`
    );
    if (result) {
      await axios
        .delete(`/user/delete/${user.id}`)
        .then((resp) => {
          if (resp.status === 200) {
            fetchTeam();
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };

  const handleNewMemberEmailChange = (event) => {
    setNewMemberEmail(event.target.value);
  };

  return (
    <div className='w-full'>
      <div className='bg-gray-100'>
        <div className='max-w-7xl mx-auto md:p-0'>
          <h1 className='font-bold text-3xl mt-4'>
            Analysis: <span className='text-orange'>{team.name}</span>
          </h1>
          <div className='mt-4 flex items-start justify-between flex-wrap'>
            {getPeople().map((list, i) => (
              <ul className='divide-y divide-gray-200 w-96 mr-10 mt-10' key={i}>
                {list.map((member) => (
                  <li key={member.id} className='py-4 flex justify-between'>
                    <div>
                      <Link to={`/report/${member.id}`}>
                        <p className='text-sm font-medium text-gray-700 hover:text-gray-900'>
                          {member.name || member.email}
                        </p>
                        <p className='text-sm text-gray-500 capitalize hover:text-gray-900'>
                          {member.title || member.role}
                        </p>
                      </Link>
                      {re[member.id] ||
                      member.categories.some((c) => !!c.hours) ? (
                        ''
                      ) : (
                        <button
                          className='text-green-500 text-base font-medium hover:text-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange'
                          onClick={() => resendEmail(member.id)}
                        >
                          Resend email
                        </button>
                      )}
                    </div>

                    <div className='flex items-start'>
                      {member.categories.some(
                        (category) => !!category.hours
                      ) ? (
                        <CheckIcon className='w-6 text-green-500' />
                      ) : (
                        <CloseIcon className='w-6 text-red-500' />
                      )}
                      {currentUser.role === 'sa' && (
                        <TrashIcon
                          className='w-6 text-gray-400 hover:text-gray-800 cursor-pointer'
                          onClick={() => deleteMember(member)}
                        />
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            ))}
            <div>
              <h2 className='w-full text-center text-lg font-bold'>
                Team total
              </h2>
              <div className='w-64 h-64 text-xs pb-6'>
                {!!teamChart && (
                  <PieChart
                    data={teamChart}
                    margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                    clickHandler={(n) => {
                      setModalCategory(n);
                      setModalOpen(true);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className='mb-6 w-96'>
            <label
              htmlFor='email'
              className='block text-sm font-medium text-gray-700'
            >
              Add member:
            </label>
            <div className='mt-1 flex rounded-md'>
              <div className='relative flex items-stretch flex-grow focus-within:z-10'>
                <input
                  type='text'
                  name='email'
                  id='email'
                  value={newMemberEmail}
                  onChange={handleNewMemberEmailChange}
                  className='focus:ring-orange focus:border-orange block w-full rounded-none rounded-l-md sm:text-sm border-gray-300'
                  placeholder='john@doe.com'
                />
              </div>
              <button
                onClick={addMember}
                className='-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-orange'
              >
                <span>Add</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-white w-screen -ml-4 p-4'>
        <div className='max-w-7xl mx-auto md:p-0'>
          <div className='mt-10 w-full bg-white divide-y divide-gray-200'>
            <h3 className='font-bold text-xl'>By team member</h3>
            {team.users
              .filter((u) => u.categories.some((c) => !!c.hours))
              .map((member) => (
                <div key={member.id} className='pb-10 pt-4'>
                  <Link to={`/report/${member.id}`}>
                    <p className='text-gray-900 font-medium'>{member.name}</p>
                    <p className='text-sm text-gray-500 capitalize'>
                      {member.title || member.role}
                    </p>
                    <div className='mt-6 flex items-start justify-between flex-wrap'>
                      <div className='w-96'>
                        <p className='font-medium'>
                          Weekly distribution of hours:
                        </p>
                        {member.chartData?.map(
                          ({ name, value, percentage, color }) => (
                            <div
                              key={name}
                              className='relative w-full h-10 my-4'
                            >
                              <div
                                style={{
                                  width: `${percentage}%`,
                                  backgroundColor: color,
                                }}
                                className='h-full'
                              />
                              <span
                                style={{ left: `${percentage + 1}%` }}
                                className='text-sm absolute top-1/2 transform -translate-y-1/2'
                              >
                                {name} <b>{value}h</b> ({percentage}%)
                              </span>
                            </div>
                          )
                        )}
                      </div>
                      <div className='w-96'>
                        <p className='font-medium'>Greatest Inefficiency:</p>
                        <p className='w-full border-gray-400 border border-solid p-2 rounded-md text-gray-500 mb-2'>
                          {member.taskNotes1 || '-'}
                        </p>
                        <p className='font-medium'>Largest pain point:</p>
                        <p className='w-full border-gray-400 border border-solid p-2 rounded-md text-gray-500 mb-2'>
                          {member.taskNotes2 || '-'}
                        </p>
                        <p className='font-medium'>Biggest win:</p>
                        <p className='w-full border-gray-400 border border-solid p-2 rounded-md text-gray-500'>
                          {member.taskNotes3 || '-'}
                        </p>
                      </div>
                      <div className='w-64 text-xs h-64'>
                        {!!member.chartData && (
                          <PieChart
                            data={member.chartData}
                            margin={{
                              top: 10,
                              right: 20,
                              bottom: 10,
                              left: 20,
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </div>
      <CategoriesModal
        isOpen={isModalOpen}
        category={modalCategory}
        team={team}
        closeModal={() => setModalOpen(false)}
      />
    </div>
  );
};

export default ViewTeamReports;
